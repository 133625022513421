import React from "react";

import { Images } from "../../constants/Images";
import "./QuickView.css";
import Layout from "../../components/layout/Layout";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import TextInputWithLabel from "../../components/applicationInput/ApplicationInput";
import ShoppingStep from "../../components/commonShoppingStep/ShoppingStep";
import OwlCarouselBp from "../../components/owlCarousel/OwlCrowselBp";
import RequestQuotation from "../../components/requestQuotation/RequestQuotation";

const BagPackView = () => {
  return (
    <Layout>
      <div className="quick-view-section">
        <section className="firstSectionBagPack">
          <div className="container">
            <div className="schoolBagSection">
              <div className="leftSchoolBagSection">
                <img
                  alt="backpack"
                  src={Images.backpackBlue}
                  className="img-fluid"
                />
              </div>
              <div className="rightSchoolBagSection">
                <h5>Bags & Accessories</h5>
                <p className="bagpack-text-p-css">
                  Custoking’s school bags are crafted from durable, lightweight materials for daily comfort and longevity.Designed with multiple compartments, they keep books, stationery, and essentials organized.
                </p>
                <p className="bagpack-text-p-css">
                  Customizable with your school’s logo and colors, our bags offer a personalized look that students of all ages will love
                </p>
                <p className="bagpack-text-p-css">
                  With ergonomic designs to reduce shoulder strain, these bags combine style, practicality, and durability. Available in various sizes and styles, Custoking bags are the perfect blend of quality and functionality for everyday school use.
                </p>
                <div className="eventHostingBtn">
                  <ApplicationButton
                    btnText={"Request For Quotation"}
                    type="submit"
                    className="blackheight45 radius30"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="secondSectionBagPack crowsel-section-bp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="row align-items-center">
                  <div className="col-lg-2 col-md-3">
                    <div className="accessoriesBox">
                      <img
                        alt="backpack"
                        src={Images.formateImgs}
                        className="img-fluid"
                        width={60}
                      />
                      <p className="accessories-text-bp">Accessories</p>
                    </div>
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <OwlCarouselBp />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="thirdSectionBagPack">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h5>Accessories</h5>
                <p className="bagpack-text-p-css">
                  Custoking’s school accessories collection combines functionality and style, designed to enhance students' everyday experience.
                </p>
                <p className="bagpack-text-p-css">
                  Crafted from high-quality, durable materials, our accessories include everything from lunch boxes and water bottles to pencil cases and lanyards—each item tailored for long-lasting use
                </p>
                <p className="bagpack-text-p-css">
                  Customizable with your school’s logo and colors, these accessories create a cohesive and branded look for your school community.
                </p>
                <p className="bagpack-text-p-css">With ergonomic designs that prioritize ease of use, Custoking accessories offer both convenience and a touch of personalization, making them a must-have for students, parents, and faculty alike.</p>
                <div className="eventHostingBtn">
                </div>
              </div>
              <div className="col-md-6">
                <img alt="backpack" src={Images.groupPen} className="img-fluid groupPenImage" />
              </div>
            </div>
          </div>
        </section>
        <section className="fourthSectionBagPack">
          <RequestQuotation title="Get School Bag & Accessories Quotation"/>
        </section>
        <section className="fifthSectionBagPack">
          <div className="shopping-step-qv">
            <ShoppingStep />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default BagPackView;
