import React from 'react';
import './CountryList.css';
import { Images } from '../../constants/Images';
const CountryListComp = () => {
    return (
        <img src={Images.countryList} alt="countrylist" className="img-fluid countryListImg" />
    );
};

export default CountryListComp;
