// import React, { useEffect, useRef } from 'react';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './OwlCarousel.css';
// import { Images } from '../../constants/Images';
// import Slider from 'react-slick';

// const OwlCarouselQuickView = () => {
//   const sliderRef = useRef(null);

//   useEffect(() => {
//     if (sliderRef.current) {
//       sliderRef.current.slickGoTo(0);
//     }
//   }, []);

//   const settings = {
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     infinite: true,
//     speed: 500,
//     dots: true,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     focusOnSelect: true,
//     cssEase: 'linear',
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           infinite: true,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           dots: true,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="wrapper">
//       <Slider ref={sliderRef} {...settings}>
//        <div><img src={Images.quickViewleft} alt="Slide 1"  className='img-fluid'/></div>
//         <div><img src={Images.quickViewcenter} alt="Slide 2"  className='img-fluid' /></div>
//         <div><img src={Images.quickViewright} alt="Slide 2"  className='img-fluid' /></div>
//       </Slider>
//     </div>
//   );
// };

// export default OwlCarouselQuickView;

import React from "react";
import "./OwlCarousel.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Images } from "../../constants/Images";
import ApplicationButton from "../applicationButton/ApplicationButton";

const OwlCarouselQuickView = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <OwlCarousel className="owl-theme owlCarouselEventHosting" {...options}>
      <div className="item">
        <img src={Images.quickViewleft} alt="Slide 1" />
      </div>
      <div className="item">
        <img src={Images.quickViewcenter} alt="Slide 2" />
      </div>
      <div className="item">
        <img src={Images.quickViewright} alt="Slide 3" />
      </div>
      <div className="item">
        <img src={Images.quickViewcenter} alt="Slide 3" />
      </div>
    </OwlCarousel>
  );
};

export default OwlCarouselQuickView;
