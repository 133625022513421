export const Icons = {
    searchIcon: require("../assests/icons/searchIcon.png"),
    calenderIcon: require("../assests/icons/calenderIcon.png"),
    dayNightIcon: require("../assests/icons/dayNightIcon.png"),
    superSale: require("../assests/icons/superSale.png"),
    phoneIcon: require("../assests/icons/phoneIcon.png"),
    eyeIcon: require("../assests/icons/eye.png"),
    iconPhoneCard: require("../assests/icons/iconPhoneCard.png"),
    shoppingCartIcon: require("../assests/icons/shoppingIcon.png"),
    deliveryVan: require("../assests/icons/deliveryVanicon.png"),
    exchnageIcon: require("../assests/icons/exchangeIcon.png"),
    phoneWithBg: require("../assests/icons/phoneWithBg.png"),
    fourGrid: require("../assests/icons/fourGrid.png"),
    qualityAssurance: require("../assests/icons/qualityAssurance.png"),
    longTerm: require("../assests/icons/longTerm.png"),
    expertiseRely: require("../assests/icons/expertiseRely.png"),
    starBlack: require("../assests/icons/starBlack.png"),
    userDOT3: require("../assests/icons/usericondot3.png"),
    userTick: require("../assests/icons/tickUsreiocn.png"),
    parselVan: require("../assests/icons/parselVan.png"),
    rightGrey: require("../assests/icons/rightGrey.png"),
    callUs: require("../assests/icons/callUs.png"),
    helpCenter: require("../assests/icons/helpCenter.png"),
    messageIcon: require("../assests/icons/messageIcon.png"),
  };