export const Images = {
    custokingLogo: require("../assests/images/custokingLogo.png"),
    carousel1: require("../assests/images/carousel1.png"),
    carousel2: require("../assests/images/carousel2.png"),
    carousel3: require("../assests/images/carousel3.png"),
    carousel4: require("../assests/images/carousel4.png"),
    carousel5: require("../assests/images/carousel5.png"),
    carousel6: require("../assests/images/carousel6.png"),
    stationaryHeroPage: require("../assests/images/stationary-hero-page.png"),
    schoolUniformsImg: require("../assests/images/school-uniforms.png"),
    groupGirls: require("../assests/images/groupgirls.png"),
    idCardImg: require("../assests/images/idCardImg.png"),
    schoolUniform: require("../assests/images/schoolUniform.png"),
    stationaryImg: require("../assests/images/stationaryImg.png"),
    groupDressImg: require("../assests/images/groupDressImg.png"),
    countryList: require("../assests/images/countryList.png"),
    category1: require("../assests/images/category1.png"),
    category2: require("../assests/images/category2.png"),
    category3: require("../assests/images/category3.png"),
    completeCustomization: require("../assests/images/completeCustomization.png"),
    pioneered: require("../assests/images/pioneered.png"),
    poweredTechnology: require("../assests/images/poweredTechnology.png"),
    qualitiesTrust: require("../assests/images/qualitiesTrust.png"),
    highQualityPersonalized: require("../assests/images/highQualityPersonalized.png"),
    customUniform: require("../assests/images/customUniform.png"),
    tieBeltImg: require("../assests/images/tie-belt.png"),
    cardimgGirls: require("../assests/images/id-cardimgGirls.png"),
    redSchoolUniforms: require("../assests/images/redSchoolUniforms.png"),
    idCartSimple: require("../assests/images/idCartSimple.png"),
    eventHostIdCard: require("../assests/images/eventHostIdCard.png"),
    schollUnifromIdCard: require("../assests/images/schollUnifromIdCard.png"),
    stationaryIdCard: require("../assests/images/stationaryIdCard.png"),
    greenNotebook: require("../assests/images/greenNotebook.png"),
    multipleNoteBook: require("../assests/images/multipleNoteBook.png"),
    simpleNotebook: require("../assests/images/simpleNotebook.png"),
    dairyNotebook: require("../assests/images/dairyNotebook.png"),
    pensnadPencil: require("../assests/images/pensnadPencil.png"),
    bottleGreen: require("../assests/images/bottle-green.png"),
    tagUniform: require("../assests/images/tagUniform.png"),
    stickWall: require("../assests/images/stickWall.png"),
    schoolBag: require("../assests/images/schoolBag.png"),
    spiralNoteBookWhite: require("../assests/images/spiralNoteBookWhite.png"),
    spiralBrownNb: require("../assests/images/spiralBrownNb.png"),
    yelowBlueNotebook: require("../assests/images/yelowBlueNotebook.png"),
    blackNoteBook: require("../assests/images/blackNoteBook.png"),
    gropuNotebook: require("../assests/images/gropuNotebook.png"),
    schoolUniform2: require("../assests/images/schoolUniform2.png"),
    schoolUniform3: require("../assests/images/schoolUniform3.png"),
    erpBanner: require("../assests/images/erp-banner-img.png"),
    processStepImg: require("../assests/images/processStepImg.png"),
    revolutionErpImg: require("../assests/images/revolutionErpImg.png"),
    erpSystemImg: require("../assests/images/erpSystemImg.png"),
    fourZeroImg: require("../assests/images/four-zero-img.png"),
    rightArrow: require("../assests/images/rightArrow.png"),
    seasonalWeekly: require("../assests/images/seasonalWeekly.png"),
    dummyLogo1: require("../assests/images/dummyLogo1.png"),
    visaLogo: require("../assests/images/visa-logo.png"),
    Mastercard: require("../assests/images/Mastercard.png"),
    GooglePay: require("../assests/images/GooglePay.png"),
    ApplePay: require("../assests/images/ApplePay.png"),
    PayPal: require("../assests/images/PayPal.png"),
    idcard1: require("../assests/images/idcard1.png"),
    idcard2: require("../assests/images/idcard2.png"),
    idCard3: require("../assests/images/idCard3.png"),
    uniformMainImgQuickView: require("../assests/images/uniform-main-img.png"),
    childrenDress: require("../assests/images/children-dress.png"),
    quickViewleft: require("../assests/images/quick-view-left.png"),
    quickViewcenter: require("../assests/images/quick-view-center.png"),
    quickViewright: require("../assests/images/quick-view-right.png"),
    backpackBlue: require("../assests/images/backpackBlue.png"),
    formateImgs: require("../assests/images/formate-imgs.png"),
    groupPen: require("../assests/images/groupPen.png"),
    eventHosting: require("../assests/images/event-hosting.png"),
    eventHostingPinkDress: require("../assests/images/eventHostingPinkDress.png"),
    uniform1: require("../assests/images/uniform1.png"),
    stationaryImage: require("../assests/images/stationaryImage.png"),
    giftPromotation: require("../assests/images/giftPromotation.png"),
    eventsPromotion: require("../assests/images/eventsPromotion.png"),
    customizedUniform: require("../assests/images/customizedUniform.png"),
    newUniformSchool: require("../assests/images/new-school-uniform.png"),
    newClientsImg: require("../assests/images/clients-img.png"),
    blueSUniform: require("../assests/images/blueSUniform.png"),
    balczerUniform: require("../assests/images/balczerUniform.png"),
    redwithblackpant: require("../assests/images/redwithblackpant.png"),
    idCardNew1: require("../assests/images/idCardNew1.png"),
    idCardNew2: require("../assests/images/idCardNew2.png"),
    idCardNew3: require("../assests/images/idCardNew3.png"),
    idCardNew4: require("../assests/images/idCardNew4.png"),
    staionaryNew1: require("../assests/images/staionaryNew1.png"),
    staionaryNew2: require("../assests/images/staionaryNew2.png"),
    staionaryNew3: require("../assests/images/staionaryNew3.png"),
    staionaryNew4: require("../assests/images/staionaryNew4.png"),
    giftAndPromotion1: require("../assests/images/giftAndPromotion1.png"),
    giftAndPromotion2: require("../assests/images/giftAndPromotion2.png"),
    giftAndPromotion3: require("../assests/images/giftAndPromotion3.png"),
    giftAndPromotion4: require("../assests/images/giftAndPromotion4.png"),
    hoodie: require("../assests/images/hoodie.png"),
    multiColorUniform: require("../assests/images/multi-color-uniform.png"),
    customisedSchoolIMG: require("../assests/images/customisedSchoolIMG.png"),
    photograferImg: require("../assests/images/photograferImg.png"),
    abhiId: require("../assests/images/abhiId.png"),
    eventHostingNew1: require("../assests/images/eventHostingNew1.png"),
    eventHostingNew2: require("../assests/images/eventHostingNew2.png"),
    eventHostingNew3: require("../assests/images/eventHostingNew3.png"),
    eventHostingNew4: require("../assests/images/eventHostingnew4.png"),
    stationaryMain: require("../assests/images/stationaryMain.png"),
    stCrowsel1: require("../assests/images/stCrowsel1.png"),
    stCrowsel2: require("../assests/images/stCrowsel2.png"),
    stCrowsel3: require("../assests/images/stCrowsel3.png"),
    gtandp: require("../assests/images/giftandPMian.png"),
    giftnandPBanner: require("../assests/images/giftnandPBanner.png"),
    gtAndP3sECTION: require("../assests/images/gtAndP3sECTION.png"),
    gtbanner3section: require("../assests/images/gtbanner3section.png"),
    contactUsBg: require("../assests/images/contactUsBg.png"),
    officeImage: require("../assests/images/officeImage.png"),
  };