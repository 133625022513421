import React from "react";
import "./Layout.css";
import ApplicationHeader from "../applicationHeader/ApplicationHeader";
import Footer from "../footer/Footer";
const Layout = ({ children }) => {
  return (
    <div className="layoutContainer">
      <ApplicationHeader />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
