import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Images } from '../../constants/Images';

const images = [
  Images.idcard1,
  Images.idcard2,
  Images.idcard1,
];

function ReactResponsiveCarousel() {
  return (
    <Carousel
      showThumbs
      infiniteLoop
      showStatus={false}
      showIndicators={false}
      swipeable
    >
      {images.map((src, index) => (
        <div key={index}>
          <img src={src} alt={`carousel-${index}`} />
        </div>
      ))}
    </Carousel>
  );
}

export default ReactResponsiveCarousel;