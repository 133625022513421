import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";

const RequestQuotation = ({ title }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      phoneNumber: "",
    },
    validationSchema: yup.object({
      email: yup.string().required("Email is required"),
      phoneNumber: yup.string().required("Phone Number is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log(values, "jjjjjjj");
    },
  });
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-md-9 col-sm-12">
            <div className="idCardCatalog">
              <h5>{title}</h5>
              <h6>Submit details and we will send you the catalog</h6>
              <form onSubmit={formik.handleSubmit} className="w-100">
                <div className="inputBox">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email (Required)"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />

                  {formik.touched.email && formik.errors.email ? (
                    <div className="Inputerror mt-0 pt-0">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
                <div className="inputBox">
                  <input
                    type="number"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Phone Number (Required)"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <div className="Inputerror mt-0 pt-0 text-denger">
                      {formik.errors.phoneNumber}
                    </div>
                  ) : null}
                </div>
                <div class="requestForQuotation requestForIdCard">
                  <button type="submit">Request For Quotation</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestQuotation;
