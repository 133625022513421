import React from "react";
import "./Contact.css";
import Layout from "../../components/layout/Layout";
import { Images } from "../../constants/Images";
import { Icons } from "../../constants/Icons";
import * as yup from "yup";
import { useFormik } from "formik";
const ContactUs = () => {
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      subject: "",
      message: "",
    },
    validationSchema: yup.object({
      firstName: yup.string().required("firstName  is required"),
      lastName: yup.string().required("last Name is required"),
      email: yup.string().required("email is required"),
      phoneNumber: yup.string().required("phoneNumber is required"),
      subject: yup.string().optional(),
      message: yup.string().optional(),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log(values,"asjjklf");
    },
  });
  return (
    <Layout>
      <div className="mainContainer">
        <section className="firstContactSection">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 p-0">
                <img src={Images.contactUsBg} alt="bg" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
        <section className="secondContactSection">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <h5>Contact our specialists</h5>
                <p>
                  Fill out the form below and we will reply within 24 hours.
                </p>
                <p>
                  You may also directly reach out to us at{" "}
                  <a href="mailto:work@custoking.com" className="workEmail">
                    work@custoking.com
                  </a>
                </p>
                <form onSubmit={formik.handleSubmit}>
                  <div className="contactForm">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="inputLabelDiv">
                          <h6>First name *</h6>
                          <input
                            type="text"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <div className="Inputerror mt-0 pt-0 text-denger">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="inputLabelDiv">
                          <h6>Last name *</h6>
                          <input
                            type="text"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="Inputerror mt-0 pt-0 text-denger">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="inputLabelDiv">
                          <h6>Email *</h6>
                          <input
                            type="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="Inputerror mt-0 pt-0 text-denger">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="inputLabelDiv">
                          <h6>Phone number *</h6>
                          <input
                            type="number"
                            name="phoneNumber"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phoneNumber}
                          />
                          {formik.touched.phoneNumber &&
                          formik.errors.phoneNumber ? (
                            <div className="Inputerror mt-0 pt-0 text-denger">
                              {formik.errors.phoneNumber}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="inputLabelDiv">
                          <h6>Subject</h6>
                          <input
                            type="text"
                            name="subject"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.subject}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="inputLabelDiv">
                          <h6>Message</h6>
                          <textarea
                            rows={4}
                            type="text"
                            name="message"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.message}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="submitContactBtn">
                          <button type="submit">Send message</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="thirdContactSection">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="contactInfo">
                  <div className="callUsDirectlyBox">
                    <img src={Icons.callUs} alt="call" className="img-fluid" />
                    <p>Call us directly</p>
                    <h6>7478004111</h6>
                  </div>
                  <div className="callUsDirectlyBox">
                    <img
                      src={Icons.messageIcon}
                      alt="message"
                      className="img-fluid"
                    />
                    <p>Send us a message</p>
                    <h6>work@custoking.com</h6>
                  </div>
                  <div className="callUsDirectlyBox">
                    <img
                      src={Icons.helpCenter}
                      alt="help center"
                      className="img-fluid"
                    />
                    <p>Looking for support?</p>
                    <h6>Help Center</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="fourthContactSection">
          <div className="container">
            <div className="fourthContactContainer">
              <div className="row align-items-center rowFourthContact">
                <div className="col-sm-6">
                  <img
                    src={Images.officeImage}
                    alt="office"
                    className="img-fluid"
                  />
                </div>
                <div className="col-sm-6">
                  <div className="officeContactAddress">
                    <h5>Our office</h5>
                    <p>New York 11741, USA</p>
                    <p>396 Lillian Bolavandy, Holbrook</p>
                    <div className="showOnMap">
                      <span>Show on map &gt;</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ContactUs;
