import React from "react";

import { Images } from "../../constants/Images";
import "./StationaryView.css";
import Layout from "../../components/layout/Layout";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import ShoppingStep from "../../components/commonShoppingStep/ShoppingStep";
import OwlCarouselSt from "../../components/owlCarousel/OwlCrouselSt";
import { Icons } from "../../constants/Icons";
import RequestQuotation from "../../components/requestQuotation/RequestQuotation";

const StationaryView = () => {
  return (
    <Layout>
      <div className="quick-view-section">
        <section className="firstSectionBagPack">
          <div className="container">
            <div className="schoolBagSection">
              <div className="leftSchoolBagSection">
                <img
                  alt="backpack"
                  src={Images.stationaryMain}
                  className="img-fluid"
                />
              </div>
              <div className="rightSchoolBagSection">
                <h2 className="text-center">Stationary</h2>
                <p className="bagpack-text-p-css mt-4">
                  Custoking’s school bags are crafted from durable, lightweight materials for daily comfort and longevity.Designed with multiple compartments, they keep books, stationery, and essentials organized.
                </p>
                <p className="bagpack-text-p-css">
                  Customizable with your school’s logo and colors, our bags offer a personalized look that students of all ages will love
                </p>
                <p className="bagpack-text-p-css">
                  With ergonomic designs to reduce shoulder strain, these bags combine style, practicality, and durability. Available in various sizes and styles, Custoking bags are the perfect blend of quality and functionality for everyday school use.
                </p>
                <div className="eventHostingBtn">
                  <ApplicationButton
                    btnText={"Request For Quotation"}
                    type="submit"
                    className="blackheight45 radius30"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="secondSectionBagPack crowsel-section-bp-st">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="row align-items-center">
                  <div className="col-lg-2 col-md-3">
                    <div className="accessoriesBox">
                      <img
                        alt="backpack"
                        src={Images.formateImgs}
                        className="img-fluid"
                        width={60}
                      />
                      <p className="accessories-text-bp">Stationary</p>
                    </div>
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <OwlCarouselSt />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="thirdSectionBagPack">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="rightSideStaffAndStudent ps-0">
                  <h5>What sets us apart ?</h5>
                  <div className="staffandStudentBox">
                    <div className="d-flex align-items-center">
                      <img src={Icons.rightGrey} alt="" />
                      <h6>Tailored Stationery Designs</h6>
                    </div>
                    <p>
                      Our stationery is crafted to reflect your school’s unique identity, with personalized options that add a distinct touch to every piece.
                    </p>
                  </div>
                  <div className="staffandStudentBox">
                    <div className="d-flex align-items-center">
                      <img src={Icons.rightGrey} alt="" />
                      <h6>Premium Quality</h6>
                    </div>
                    <p>
                      We use high-quality materials to create elegant and durable stationery that stands out and makes a lasting impression
                    </p>
                  </div>
                  <div className="staffandStudentBox">
                    <div className="d-flex align-items-center">
                      <img src={Icons.rightGrey} alt="" />
                      <h6>Comprehensive Service</h6>
                    </div>
                    <p>
                      From concept to delivery, we handle every step, making your stationery ordering experience smooth and effortless
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex justify-content-end">
                  <img src={Images.groupPen} alt="" className="img-fluid groupPenImage" />
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-6">
                <p className="bagpack-text-p-css">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis
                  ac nibh nullam feugiat. Accumsan vestibulum varius arcu posuere
                  orci vitae sagittis posuere vitae.
                </p>
                <p className="bagpack-text-p-css">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis
                  ac nibh nullam feugiat. Accumsan vestibulum varius arcu posuere
                  orci vitae sagittis posuere vitae.
                </p>
                <p className="bagpack-text-p-css">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis
                  ac nibh nullam feugiat. Accumsan vestibulum varius arcu posuere
                  orci vitae sagittis posuere vitae.
                </p>
                <div className="eventHostingBtn">
                  <ApplicationButton
                    btnText={"Request For Quotation"}
                    type="submit"
                    className="blackBtn40"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <img alt="backpack" src={Images.groupPen} className="img-fluid groupPenImage" />
              </div>
            </div> */}
          </div>
        </section>
        <section className="fourthSectionBagPack">
            <RequestQuotation title="Get School Bag & Accessories Quotation"/>
        </section>
        <section className="fifthSectionBagPack">
          <div className="shopping-step-qv">
            <ShoppingStep />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default StationaryView;
