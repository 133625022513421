import React, { useRef, useState } from "react";
import Layout from "../../components/layout/Layout";
import "./ProductListPage.css";
import { Carousel } from "react-bootstrap";
import { Images } from "../../constants/Images";
import {
  bagsAndAccessories,
  eventHosting,
  productGiftAndPromotion,
  productListData,
  productListDataIdSection,
  productListDataNoteBookSection,
  productsListDto,
} from "../../components/dtos/PlpData";
import CommonCardBts from "../../components/commonCard/CommonCard";
import ShoppingStep from "../../components/commonShoppingStep/ShoppingStep";
import CountryListComp from "../../components/CommonCountryList/CountryListComp";
import ClientsOwlCarousel from "../../components/owlCarousel/ClientsOwlCarousel";

const ProductListPage = () => {
  const [index, setIndex] = useState(0);
  const sectionRefs = useRef(
    productsListDto.reduce((acc, product) => {
      acc[product.id] = React.createRef();
      return acc;
    }, {})
  );

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const scrollToRef = (id) => {
    const section = sectionRefs.current[id];
    if (section && section.current) {
      section.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Layout>
      <div className="mainContainer">
        <section className="firstSectionProduct">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 p-0">
                <Carousel activeIndex={index} onSelect={handleSelect}>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={Images.stationaryHeroPage}
                      alt="Carousel slide"
                    />
                  </Carousel.Item>
                 <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={Images.carousel2}
                      alt="Carousel slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={Images.carousel3}
                      alt="Carousel slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={Images.carousel4}
                      alt="Carousel slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={Images.carousel5}
                      alt="Carousel slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={Images.carousel6}
                      alt="Carousel slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </section>
        <section className="secondSectionProduct">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12 overflow-div">
                <div className="productsListBox">
                  {productsListDto.map((product) => (
                    <div key={product.id} className="product-label-div">
                      <input
                        type="radio"
                        name="product"
                        id={product.id}
                        onClick={() => scrollToRef(product.id)}
                      />
                      <label htmlFor={product.id} className="product-label">
                        <span>{product.label}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="thirdSectionProduct">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-12"
                ref={sectionRefs.current["schoolUniforms"]}
                id="schoolUniforms"
              >
                <div className="heading-product-school-uniforms">
                  <p> School Uniforms</p>
                </div>
                <div className="productsSection">
                  {productListData.map((product) => (
                    <CommonCardBts
                      key={product.id}
                      id={product.id}
                      title={product.title}
                      description={product.description}
                      image={product.image}
                      route={product.route}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-12"
                ref={sectionRefs.current["idCard"]}
                id="idCard"
              >
                <div className="heading-product-school-uniforms">
                  <p>School ID Card</p>
                </div>
                <div className="productsSection">
                  {productListDataIdSection.map((product) => (
                    <CommonCardBts
                      key={product.id}
                      id={product.id}
                      title={product.title}
                      description={product.description}
                      image={product.image}
                      route={product.route}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-12"
                ref={sectionRefs.current["stationary"]}
                id="stationary"
              >
                <div className="heading-product-school-uniforms">
                  <p>Stationary</p>
                </div>
                <div className="productsSection">
                  {productListDataNoteBookSection.map((product) => (
                    <CommonCardBts
                      key={product.id}
                      id={product.id}
                      title={product.title}
                      description={product.description}
                      image={product.image}
                      route={product.route}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-12"
                ref={sectionRefs.current["giftAndPromotion<"]}
                id="giftAndPromotion"
              >
                <div className="heading-product-school-uniforms">
                  <p>Gift And Promotion</p>
                </div>
                <div className="productsSection">
                  {productGiftAndPromotion.map((product) => (
                    <CommonCardBts
                      key={product.id}
                      id={product.id}
                      title={product.title}
                      description={product.description}
                      image={product.image}
                      route={product.route}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-12"
                ref={sectionRefs.current["eventHosting"]}
                id="eventHosting"
              >
                <div className="heading-product-school-uniforms">
                  <p>Event Hosting</p>
                </div>
                <div className="productsSection">
                  {eventHosting.map((product) => (
                    <CommonCardBts
                      key={product.id}
                      id={product.id}
                      title={product.title}
                      description={product.description}
                      image={product.image}
                      route={product.route}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="fourthSection fourthSectionProduct">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="qualitiesAssuranceBox">
                  <img
                    src={Images.highQualityPersonalized}
                    className="img-fluid w-100"
                    alt="pioneered"
                  />
                  <div className="insideContent">
                    <h5>High Quality Personalized</h5>
                    <p>Wear your Tag with Pride</p>
                    <button>Request For Quotation</button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="qualitiesAssuranceBox">
                  <img
                    src={Images.customUniform}
                    className="img-fluid w-100"
                    alt="pioneered"
                  />
                  <div className="insideContent">
                    <h5>Custom Uniform Wear</h5>
                    <p>Showcase your brand with custom winter wear.</p>
                    <button>Request For Quotation</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="fifthSectionProduct">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-12"
                ref={sectionRefs.current["writingSupplies"]}
                id="writingSupplies"
              >
                <div className="heading-product-school-uniforms">
                  <p>Bags & Accessories</p>
                </div>
                <div className="productsSection">
                  {bagsAndAccessories.map((product) => (
                    <CommonCardBts
                      key={product.id}
                      id={product.id}
                      title={product.title}
                      description={product.description}
                      image={product.image}
                      route={product.route}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-12"
                ref={sectionRefs.current["accessories"]}
                id="accessories"
              >
                <div className="heading-product-school-uniforms">
                  <p>Bags & Accessories</p>
                </div>
                <div className="productsSection">
                  {bagsAndAccessories.map((product) => (
                    <CommonCardBts
                      key={product.id}
                      id={product.id}
                      title={product.title}
                      description={product.description}
                      image={product.image}
                      route={product.route}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="seventhSection mb-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="sectionHeader">
                  <h5 className="mb-0">Our Clients</h5>
                </div>
                <ClientsOwlCarousel />
              </div>
            </div>
          </div>
        </div>
        <section className="seventhSectionProduct">
          <ShoppingStep />
        </section>
      </div>
    </Layout>
  );
};

export default ProductListPage;
