import React from "react";
import Layout from "../../components/layout/Layout";
import { Images } from "../../constants/Images";
import "./ErpProcess.css";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import CountryListComp from "../../components/CommonCountryList/CountryListComp";
import ShoppingStep from "../../components/commonShoppingStep/ShoppingStep";

const ErpProcess = () => {
  return (
    <Layout>
      <div className="erp-process-section">
        <div className="container-fluid">
          <div className="row banner-section-process">
            <div className="col-md-6">
              <div>
                <p className="heading-bannerErp">
                  Lorem ipsum dolor sit amet consectetur. Vestibulumitor.
                </p>
                <p className="paragraph-text-ptag">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.{" "}
                </p>
              </div>
              <div className="enquiry-btn-div">
                <ApplicationButton
                  btnText={"Enquiry"}
                  type="submit"
                  className="blackheight50"
                />
              </div>
            </div>
            <div className="col-md-6">
              <img
                src={Images.erpBanner}
                alt="erpBanner"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="row step-item-banner">
            <div className="col-md-12 d-flex justify-content-center">
              <p className="process-erp-textPtag">
                Processes automated using Custoking ERP
              </p>
            </div>
            <div className="col-md-12 d-flex justify-content-center">
              <img
                src={Images.processStepImg}
                alt="erpBanner"
                className="img-fluid"
              />
            </div>
            <div className="d-flex justify-content-between mt-3 step-container">
              <div className="step-item">
                <p>Class Creation</p>
              </div>
              <div className="step-item">
                <p>Communication</p>
              </div>
              <div className="step-item">
                <p>Attendance</p>
              </div>
              <div className="step-item">
                <p>Exams</p>
              </div>
              <div className="step-item">
                <p>Fees</p>
              </div>
              <div className="step-item">
                <p>Events</p>
              </div>
              <div className="step-item">
                <p>Teacher Module</p>
              </div>
            </div>
          </div>
          <div className="row banner-erp-system">
            <div className="col-md-6">
              <img
                src={Images.revolutionErpImg}
                alt="erpBanner"
                className="img-fluid revolutionErpImg"
              />
            </div>
            <div className="col-md-6">
              <img
                src={Images.erpSystemImg}
                alt="erpBanner"
                className="img-fluid erpSystemImg"
              />
            </div>
          </div>
          <div className="row">
          <CountryListComp/>
          </div>
        </div>
        <ShoppingStep />
      </div>
    </Layout>
  );
};

export default ErpProcess;
