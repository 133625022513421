import React from "react";
import "./OwlCarousel.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Images } from "../../constants/Images";
import ApplicationButton from "../applicationButton/ApplicationButton";

const OwlCarouselEventHosting = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <OwlCarousel className="owl-theme owlCarouselEventHosting" {...options}>
      <div className="item">
        <img src={Images.category1} alt="Slide 1" />
        <div className="sliderEventContainer">
          <h5>Spacious U-shaped Kitchen</h5>
          <p>
            Comprising three connected walls of cabinets with a practical open
            entrance.
          </p>
          <div className="eventHostingBtnBox">
           <ApplicationButton btnText={"Request For Quotation"} className={"blackBtn40"}/>
          </div>
        </div>
      </div>
      <div className="item">
        <img src={Images.category2} alt="Slide 2" />
        <div className="sliderEventContainer">
          <h5>Spacious U-shaped Kitchen</h5>
          <p>
            Comprising three connected walls of cabinets with a practical open
            entrance.
          </p>
          <div className="eventHostingBtnBox">
          <ApplicationButton btnText={"Request For Quotation"} className={"blackBtn40"}/>
          </div>
        </div>
      </div>
      <div className="item">
        <img src={Images.category3} alt="Slide 3" />
        <div className="sliderEventContainer">
          <h5>Spacious U-shaped Kitchen</h5>
          <p>
            Comprising three connected walls of cabinets with a practical open
            entrance.
          </p>
          <div className="eventHostingBtnBox">
          <ApplicationButton btnText={"Request For Quotation"} className={"blackBtn40"}/>
          </div>
        </div>
      </div>
      <div className="item">
        <img src={Images.category3} alt="Slide 3" />
        <div className="sliderEventContainer">
          <h5>Spacious U-shaped Kitchen</h5>
          <p>
            Comprising three connected walls of cabinets with a practical open
            entrance.
          </p>
          <div className="eventHostingBtnBox">
          <ApplicationButton btnText={"Request For Quotation"} className={"blackBtn40"}/>
          </div>
        </div>
      </div>
    </OwlCarousel>
  );
};

export default OwlCarouselEventHosting;
