import React from "react";
import "./GiftAndPromotion.css";
import Layout from "../../components/layout/Layout";
import { Icons } from "../../constants/Icons";
import { Images } from "../../constants/Images";
import ShoppingStep from "../../components/commonShoppingStep/ShoppingStep";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import RequestQuotation from "../../components/requestQuotation/RequestQuotation";

const GiftAndPromotion = () => {
  return (
    <Layout>
      <div className="idCardFirstSection">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <img alt="idcardpfp" className="img-fluid" src={Images.gtandp} />
            </div>
            <div className="col-md-7">
              <div className="rightSideIdCardFirstSec">
                <h5>Gifts and Promotions</h5>
                <p>
                  Enhance your school gifting experience with our exclusive
                  range of promotional items. Introducing the School Planner,
                  Custom Student Journal, Teacher Gift Set, and Elegant
                  Stationery – all specially designed for your school community.
                  Elevate your school's brand image and leave a lasting
                  impression on students, parents, and faculty with these
                  sophisticated and thoughtful gifts.
                </p>
                <div className="eventHostingBtn">
                  <ApplicationButton
                    btnText={"Request For Quotation"}
                    type="submit"
                    className="blackheight45 radius30"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="makingIdCardstep">
        <div className="container-fluid">
          <div className="row  justify-content-center">
            <div className="col-md-12 p-0">
              <img
                src={Images.giftnandPBanner}
                alt="giftnandPBanner"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="idCardSecondSection mt-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="rightSideStaffAndStudent">
                <h5>What sets us apart ?.</h5>
                <div className="staffandStudentBox">
                  <div className="d-flex align-items-center">
                    <img src={Icons.rightGrey} alt="" />
                    <h6>Customized Solutions</h6>
                  </div>
                  <p>
                    Our products are tailored to reflect your school’s unique
                    identity, ensuring a personalized touch in every gift
                  </p>
                </div>
                <div className="staffandStudentBox">
                  <div className="d-flex align-items-center">
                    <img src={Icons.rightGrey} alt="" />
                    <h6>High-Quality Materials</h6>
                  </div>
                  <p>
                    We use premium materials to craft products that are durable,
                    elegant, and functional, leaving a lasting impression on
                    recipients.
                  </p>
                </div>
                <div className="staffandStudentBox">
                  <div className="d-flex align-items-center">
                    <img src={Icons.rightGrey} alt="" />
                    <h6>End-to-End Service</h6>
                  </div>
                  <p>
                    From product design to delivery, we handle every detail,
                    making the gifting process seamless and stress-free for your
                    school.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex justify-content-end">
                <img src={Images.gtAndP3sECTION} alt="" className="img-fluid setUsApartImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pastWorkSection mt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <img src={Images.gtbanner3section} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="idCardThirdSection">
         <RequestQuotation title="Get ID Card Catalog"/>
      </div>
      <div className="idCardFourthSection">
        <ShoppingStep />
      </div>
    </Layout>
  );
};

export default GiftAndPromotion;
