import './App.css';
import ApplicationNavigator from './components/navigator/ApplicationNavigator';
function App() {
  return (
    <div className="App">
      <ApplicationNavigator />
    </div>
  );
}

export default App;