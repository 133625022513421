import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import "./EnquiryPage.css";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import TextInputWithLabel from "../../components/applicationInput/ApplicationInput";
const EnquiryPage = () => {
  const formik = useFormik({
    initialValues: {
      fullName: "",
      lastName: "",
      phone: "",
      city: "",
      state: "",
      country: "",
      message: "",
      confirmSubmit: "",
    },
    validationSchema: yup.object({
        fullName: yup.string().required("Full Name is required"),
      lastName: yup.string().required("last Name is required"),
      phone: yup.string().required("phone is required"),
      city: yup.string().required("City is required"),
      state: yup.string().required("State is required"),
      country: yup.string().required("country is required"),
      message: yup.string().required("Message required"),
      confirmSubmit: yup.string().required("Message required"),
    }),
    onSubmit: async (values, { resetForm }) => {},
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 d-flex justify-content-center">
          <p className="tellus-text">Tell Us About Your Needs Or Queries</p>
        </div>
        <div className="col-md-12 d-flex justify-content-center">
          <p className="enquiry-textPtag">ENQUIRY</p>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <TextInputWithLabel
              label={" Full Name"}
              placeHolder={"Enter Full Name"}
              name="fullName"
              customInputStyle="inline-style"
              customLabelStyle="label-text-style"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fullName}
              error={
                formik.touched.fullName && formik.errors.fullName
                  ? formik.errors.fullName
                  : null
              }
            />
          </div>
          <div className="col-md-6">
            <TextInputWithLabel
              label={"Last Name"}
              placeHolder={"Enter last Name"}
              name="lastName"
                customInputStyle="inline-style"
              customLabelStyle="label-text-style"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              error={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Phone"}
              placeHolder={"Enter  phone"}
              name="phone"
               customInputStyle="inline-style"
              customLabelStyle="label-text-style"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              error={
                formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"City"}
              placeHolder={"Enter City"}
              name="city"
                customInputStyle="inline-style"
              customLabelStyle="label-text-style"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              error={
                formik.touched.city && formik.errors.city
                  ? formik.errors.city
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"State"}
              placeHolder={"Enter State"}
              name="state"
               customInputStyle="inline-style"
              customLabelStyle="label-text-style"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.state}
              error={
                formik.touched.state && formik.errors.state
                  ? formik.errors.state
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={" Country"}
              placeHolder={"Enter Country "}
              name="country"
                 customInputStyle="inline-style"
              customLabelStyle="label-text-style"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country}
              error={
                formik.touched.country && formik.errors.country
                  ? formik.errors.country
                  : null
              }
            />
            
          </div>
          <div className="col-md-12 mt-2">
            <textarea
              className="form-control"
              id="message"
              rows="6"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
              placeholder="0/50"
            ></textarea>
            {formik.touched.message && formik.errors.message ? (
              <div className="Inputerror">{formik.errors.message}</div>
            ) : null}
          </div>
          <div className="col-md-12 mt-2">
            <div className="checkboxBox">
              <input
                id="custom-checkbox"
                name="optionalSubject"
                type="checkbox"
                className=""
                checked={formik.values.conformBtn}
                onChange={formik.handleChange}
              />
              <p className="labelCheckbox font12 fw500 mb-0">
                We allow to send updates on new product over mail and through
                voice call.
              </p>
            </div>
          </div>
        </div>
        <div className="row pt30 justify-content-start mt-2">
          <div className="col-md-4">
            <ApplicationButton
              btnText={"Submit"}
              type="submit"
              className="blackheight50"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EnquiryPage;
