import React from "react";
import { Icons } from "../../constants/Icons";
import "./ShoppingStep.css"
const ShoppingStep = () => {
  return (
    <>
      <div className="container-fluid shoppingStepContainer">
        <div className="row mt-4">
          <div className="col-md-4 col-sm-6 text-center">
            <img src={Icons.shoppingCartIcon} alt="shopping cart" className="icon-widthHight img-fluid"/>
            <h6>BULK ORDER</h6>
            <p>Get a good discount on bulk orders.</p>
          </div>
          <div className="col-md-4 col-sm-6 text-center">
          <img src={Icons.deliveryVan} alt="shopping cart" className="icon-widthHight img-fluid"/>
            <h6>PAN INDIA DELIVERY</h6>
            <p>Get your orders delivered at your doorstep.</p>
          </div>
          <div className="col-md-4 col-sm-6 text-center">
          <img src={Icons.exchnageIcon} alt="shopping cart" className="icon-widthHight img-fluid"/>
            <h6>7 DAYS EXCHANGE*</h6>
            <p>Simply return or exchange within 7 days.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShoppingStep;
