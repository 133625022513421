import React from "react";
import "./CommonCard.css";
import { Icons } from "../../constants/Icons";
import { Link } from "react-router-dom";
function CommonCardBts({ id, title, description, image, route }) {
  return (
    <div className="productsContainer" key={id}>
      <div className="productsMainBox">
        <div className="productsTopBox">
          <img alt={title} src={image} className="img-fluid" />
          <div className="productsMiddleBox">
            <p>{title}</p>
          </div>
        </div>
        <div className="productsBottomBox">
          <div className="leftProductBottom">
            <Link to={route} className="text-dec">
              <p>Enquiry</p>
            </Link>
          </div>
          <div className="rightProductBottom">
            <Link to={`/enquiry`} className="text-dec">
              <img alt="pioneered" src={Icons.phoneWithBg} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommonCardBts;
