import React from "react";
import './ApplicationHeader.css';
import { Images } from "../../constants/Images";
import { Icons } from "../../constants/Icons";
import { Navbar, Nav } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";

const ApplicationHeader = () => {
    return (
        <div className="applicationHeader">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="headerMainBox">
                            <div className="headerBox">
                                <div className="leftSideHeader">
                                    <Link to="/">
                                        <img src={Images.custokingLogo} className="logoHeader" alt="Logo" />
                                    </Link>
                                </div>
                                <div className="rightSideHeader">
                                    <div className="superSaleBox">
                                        <img src={Icons.superSale} alt="Super Sale" />
                                        <div className="rightSuperSale">
                                            <p className="mb-0 onlyThisMonth">Only this month</p>
                                            <p className="mb-0 superSale">Super Sale 20%</p>
                                        </div>
                                    </div>
                                    <div className="superSaleBox phoneIcon">
                                        <img src={Icons.phoneIcon} alt="Phone" />
                                        <p className="mb-0 phoneNumberText">7478004111</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="secondHeaderBox">
                                <Navbar expand="lg" className="custom-navbar">
                                    <Navbar.Toggle aria-controls="navbar-nav" />
                                    <Navbar.Collapse id="navbar-nav">
                                        <Nav>
                                            <Nav.Item>
                                                <NavLink to="/" className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                                                    Home
                                                </NavLink>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <NavLink to="/product" className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                                                    Product
                                                </NavLink>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <NavLink to="/customization" className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                                                    Customization
                                                </NavLink>
                                            </Nav.Item> */}
                                            <Nav.Item>
                                                <NavLink to="/contact" className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                                                    Contact Us
                                                </NavLink>
                                            </Nav.Item>
                                            <div className="rightSideLaptopHeader">
                                                <div className="superSaleBox">
                                                    <img src={Icons.superSale} alt="Super Sale" />
                                                    <div className="rightSuperSale">
                                                        <p className="mb-0 onlyThisMonth">Only this month</p>
                                                        <p className="mb-0 superSale">Super Sale 20%</p>
                                                    </div>
                                                </div>
                                                <div className="superSaleBox">
                                                    <img src={Icons.dayNightIcon} alt="Day Night" />
                                                </div>
                                                <div className="superSaleBox phoneIcon">
                                                    <img src={Icons.phoneIcon} alt="Phone" />
                                                    <p className="mb-0 phoneNumberText">0124-6882-839</p>
                                                </div>
                                                <div className="superSaleBox">
                                                    <img src={Icons.calenderIcon} alt="Calendar" />
                                                </div>
                                            </div>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplicationHeader;
