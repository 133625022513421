import React from "react";
import Layout from "../../components/layout/Layout";
import { Images } from "../../constants/Images";
import "./QuickView.css";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import CountryListComp from "../../components/CommonCountryList/CountryListComp";
import ShoppingStep from "../../components/commonShoppingStep/ShoppingStep";
import OwlCarouselQuickView from "../../components/owlCarousel/OwlCrowselQuickView";
import TextInputWithLabel from "../../components/applicationInput/ApplicationInput";
import RequestQuotation from "../../components/requestQuotation/RequestQuotation";

const QuickView = () => {
  return (
    <Layout>
      <div className="quick-view-section">
        <section className="firstSectionuniform bg-color-quick-view">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="customSchooluniformContainer">
                  <div className="leftCustomSchoolUniform">
                    <img
                      src={Images.uniformMainImgQuickView}
                      alt="uniformMainImgQuickView"
                      className="img-fluid"
                    />
                  </div>
                  <div className="rightCustomSchoolUniform">
                    <div className="enquiryBox">
                      <p className="majesty-text">
                        Custom school uniforms designed for comfort, style, and a perfect fit.
                      </p>
                      <div className="eventHostingBtn">
                        <ApplicationButton
                          btnText={"Enquiry"}
                          type="submit"
                          className="blackheight45 Btnw180 radius30"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="secondSectionUniform little-child-dress">
          <div className="container">
            <div className="row ">
              <div className="col-md-12">
                <div className="little-child-uniform-section">
                  <div className="leftLittleChildUniform">
                    <p className="anaura-text-p">
                    Primary school uniforms
                    </p>
                    <p className="vinciago-text">
                    Little buds are known for their active nature and joyful spirit. Valji fabrics aim to Provide the most durable fabrics for primary school kids that help them move freely and live fully. With Valji uniforms parents stay stressfree regarding the clothes.
                    </p>
                    <div>
                      <ApplicationButton
                        btnText={"Request For Quotation"}
                        type="submit"
                        className="blackheight45 radius30"
                      />
                    </div>
                  </div>
                  <div className="RightLittleChildUniform">
                    <img
                      src={Images.childrenDress}
                      alt="uniformMainImgQuickView"
                      className="img-fluid"
                      width={320}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="thirdSectionUniform crowsel-section-quickview">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-xl-6">
                <p className="promary-school-text">Secondary school uniforms</p>
                <p className="little-buds-ptag">
                Choose your style from the vast range of patterns in stripes, checks, and combinations. A tastefully curated palette of colors to make every child look like sunshine. Valji brings to you various material options to suit varied climatic zones. Valji kid is supposed to enjoy utmost comfort and style even when in school.
                </p>
              </div>
              <div className="col-md-12 crowsel-div">
                <OwlCarouselQuickView />
              </div>
            </div>
          </div>
        </section>
        <section className="fourthSectionUniform">
              <RequestQuotation title="Get Uniform Catalog"/>
        </section>
        <section className="fifthSectionUniform">
          <div className="shopping-step-qv">
            <ShoppingStep />
          </div>
        </section>

      </div>
    </Layout>
  );
};

export default QuickView;
