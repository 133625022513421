import React from "react"
import "./Footer.css";
import { Images } from "../../constants/Images";


const Footer=()=>{
return(
    
    <section className="Footersection">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row topFooterSec">
                <div className="col-md-4 col-sm-12">
                  <div className="LeftSecLandingCont">
                    <p>
                    Join us and stay up to date
                    </p>
                    <div className="EmailFooter">
                      <input
                        type="text"
                        placeholder="Your Email"
                        className="FooterEmail"
                      />
                      <div className="RightArrow">
                        <i className="fa-solid fa-arrow-right-long"></i>
                      </div>
                    </div>
                  </div>
                </div>
               
                <div className="col-md-4 col-6 mt20cus">
                  <div className="LeftSecLandingCont companyLeftSection">
                    <h5>Company</h5>
                    <div className="d-flex company-text-align">
                     <ul className="list-company">
                        <li>About company</li>
                        <li>Our team</li>
                        <li>Careers</li>
                        <li>Contact us</li>
                        <li>News</li>
                     </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-6 mt20cus">
                  <div className="LeftSecLandingCont">
                    <h5>Customer Service </h5>
                    <div className="d-flex customer-service-align ">
                     <ul className="customer-service">
                        <li>Payment methods</li>
                        <li>Money back guarantee</li>
                        <li>Product returns</li>
                        <li>Support center</li>
                        <li>Shipping</li>
                        <li>Term and conditions</li>
                     </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="LeftSecLandingCont">
                    <p className="allRightsReserved">
                    © All rights reserved. Made by Custoking
                    </p>
                  </div>
                </div>
                {/* <div className="col-md-8">
                  <div className="visaLogo">
                   <img src={Images.visaLogo} alt="logo" className=""/>
                   <img src={Images.Mastercard} alt="logo" className=""/>
                   <img src={Images.GooglePay} alt="logo" className=""/>
                   <img src={Images.PayPal} alt="logo" className=""/>
                   <img  src={Images.ApplePay} alt="logo" className=""/>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        </section>
)
}

export default Footer