import React from "react";
import "./IdCard.css";
import Layout from "../../components/layout/Layout";
import ReactResponsiveCarousel from "../../components/carousel/ReactResponsiveCarousel";
import { Icons } from "../../constants/Icons";
import { Images } from "../../constants/Images";
import ShoppingStep from "../../components/commonShoppingStep/ShoppingStep";
import { Card } from "react-bootstrap";
import RequestQuotation from "../../components/requestQuotation/RequestQuotation";

const IdCard = () => {
  return (
    <Layout>
      <div className="idCardFirstSection">
        <div className="container">
          <div className="row align-items-center">
            {/* <div className="col-12">
                            <div className="row">
                                <div className="col-xl-5 col-lg-6">
                                    <ReactResponsiveCarousel />
                                </div>
                                <div className="col-xl-7 col-lg-6">
                                    <div className="rightSideIdCardFirstSec">
                                        <h6 className="idCardHeader">ID Cards</h6>
                                        <div className="starRatingSec">
                                            {[1, 2, 3, 4].map((_, index) => (
                                                <div className="starRatingBox" key={index}>
                                                    <img src={Icons.starBlack} alt="star" />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="categoriesBox">
                                            <h6>Categories</h6>
                                            <p>Digital & Offset Printing Service, Office Supplies</p>
                                        </div>
                                        <div className="cardsBox">
                                            <p className="savePercentage">You Save: -30.8%</p>
                                            <p className="priceExclusive">* Price exclusive of all taxes</p>
                                            <div className="personalizedIdCard">
                                                <p className="priceExclusive">Personalized ID Cards with a professional look.</p>
                                                <ul>
                                                    <li>Material: PVC 0.8 mm thickness card.</li>
                                                    <li>Available sizes : 85mm to 54mm.</li>
                                                    <li>Decoration Technology: Digital Print</li>
                                                    <li>Decoration Area: Front and Back Side</li>
                                                    <li>Note: We are not allow to print Aadhar Cards / PAN cards / Voter</li>
                                                    <li>IDs / Driving License or any ID Cards belonging to</li>
                                                    <li>Government/Government/Quasi Government bodies.</li>
                                                    <li>Need help? Call to us.</li>
                                                    <li>Easy Delivery according to customer requirement.<br />
                                                        <span>Book your sample kit when sending us an enquiry.</span>
                                                    </li>
                                                </ul>
                                                <p className="minimumOrderQuantity">Minimum Order Quantity : 1 pcs</p>
                                                <p className="priceMention">Note : Price mention in the product is for ID Card only.</p>
                                                <div className="requestForQuotation">
                                                    <button>Request For Quotation</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            </div> */}
            <div className="col-md-5">
              <img alt="idcardpfp" className="img-fluid" src={Images.abhiId} />
            </div>
            <div className="col-md-7">
              <div className="rightSideIdCardFirstSec">
                <h5>Make Student IDCards For YourSchool with Us</h5>
                <p>
                  Get the functionality, security, and benefits of School ID
                  Cards without the investment and time needed to do it
                  yourself. Custoking provides a simple and cost-effective ID
                  Card Issuance Program for Schools.
                </p>
                <div className="requestForQuotation">
                  <button>Request For Quotation</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="makingIdCardstep">
        <div className="container">
          <div className="row  justify-content-center">
            <div className="col-md-6">
              <h5>Making School ID Cards Has Never Been Easier!</h5>
              <p>
                Running a school is hard enough, you shouldn't have to manage an
                ID Badge Printing Service as well. Get the Faculty and Student
                ID Cards with the security features you need in a few simple
                steps.
              </p>
              <div className="requestForQuotation text-center">
                <button>Get Sample</button>
              </div>
            </div>
          </div>
          <div className="row designCardSection">
            <div className="col-md-4">
              <div className="designCard">
                <img src={Icons.userDOT3} alt="" />
                <h5>1. Design</h5>
                <p>Some quick example text to build on the card title and make
                  up the bulk of the card's content.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="designCard">
                <img src={Icons.userTick} alt="" />
                <h5>2. Customize</h5>
                <p>Some quick example text to build on the card title and make
                  up the bulk of the card's content.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="designCard">
                <img src={Icons.parselVan} alt="" />
                <h5>3. Shipped</h5>
                <p>Some quick example text to build on the card title and make
                  up the bulk of the card's content.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="idCardSecondSection">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <img src={Images.idcard2} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-7">
              <div className="rightSideStaffAndStudent">
                <h5>How Staff and Student ID Cards Make a Difference.</h5>
                <div className="staffandStudentBox">
                  <div className="d-flex align-items-center">
                    <img src={Icons.rightGrey} alt="" />
                    <h6>Safe and Secure School Environment</h6>
                  </div>
                  <p>Security functionalities for Access Control and to provide quick
                    identification of everyone on campus.</p>
                </div>
                <div className="staffandStudentBox">
                  <div className="d-flex align-items-center">
                    <img src={Icons.rightGrey} alt="" />
                    <h6>Safe and Secure School Environment</h6>
                  </div>
                  <p>Security functionalities for Access Control and to provide quick
                    identification of everyone on campus.</p>
                </div>
                <div className="staffandStudentBox">
                  <div className="d-flex align-items-center">
                    <img src={Icons.rightGrey} alt="" />
                    <h6>Safe and Secure School Environment</h6>
                  </div>
                  <p>Security functionalities for Access Control and to provide quick
                    identification of everyone on campus.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="idCardThirdSection">
          <RequestQuotation title="Get ID Card Catalog"/>
      </div>
      <div className="idCardFourthSection">
        <ShoppingStep />
      </div>
    </Layout>
  );
};

export default IdCard;
