import { Images } from "../../constants/Images";

export const productsListDto = [
    { id: "schoolUniforms", label: "School Uniforms" },
    { id: "idCard", label: "ID Card" },
    { id: "stationary", label: "Stationary" },
    { id: "accessories", label: "Accessories" },
    { id: "eventHosting", label: "Event Hosting" },
  ];

  export const productListData = [
    {
      id: "idCard",
      title: "Sports Wear",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.multiColorUniform, 
      route:"/product/school-uniform"// Replace with actual image path
    },
    {
      id: "schoolUniforms",
      title: "Shirts",
      description:
        "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.newUniformSchool,
      route:"/product/school-uniform" // Replace with actual image path
    },
   
    {
      id: "eventHosting",
      title: "Blazer",
      description:
        "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.balczerUniform,
      route:"/product/school-uniform" // Replace with actual image path
    },
    {
      id: "sportsUniforms",
      title: "Full School Uniform Set",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.redwithblackpant,
      route:"/product/school-uniform" // Replace with actual image path
    },
  ];
  export const productListDataIdSection = [
    
    {
      id: "schoolUniforms",
      title: "Student Identity Card",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.idCardNew1, 
      route:"/id-card"// Replace with actual image path
    },
    {
      id: "sportsUniforms",
      title: "Teacher’s Identity Card",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.idCardNew4,
      route:"/id-card" // Replace with actual image path
    },
    {
      id: "eventHosting",
      title: "School Visitor’s Card",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.idCardNew3,
      route:"/id-card" // Replace with actual image path
    },
    
    {
      id: "idCard",
      title: "School Employee Identity Card",
      description:
        "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.idCardNew2,
      route:"/id-card" // Replace with actual image path
    },
  ];
  export const productListDataNoteBookSection = [
    {
      id: "schoolUniforms",
      title: "Pencils",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.staionaryNew1,
      route:"/stationary-view" // Replace with actual image path
    },
    {
      id: "idCard",
      title: "Pens",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.staionaryNew2,
      route:"/stationary-view" // Replace with actual image path
    },
    {
      id: "eventHosting",
      title: "Crayons",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.staionaryNew3,
      route:"/stationary-view" // Replace with actual image path
    },
    {
      id: "sportsUniforms",
      title: "Notebooks",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.staionaryNew4,
      route:"/stationary-view"// Replace with actual image path
    },
   
  ];
  export const productGiftAndPromotion = [
    {
      id: "schoolUniforms",
      title: "School Merchandise",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.giftAndPromotion1,
      route:"/giftAndPromotion" // Replace with actual image path
    },
    {
      id: "idCard",
      title: "Graduation Gifts",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.giftAndPromotion2,
      route:"/giftAndPromotion" // Replace with actual image path
    },
    {
      id: "eventHosting",
      title: "Premium Diary Set",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.giftAndPromotion3,
      route:"/giftAndPromotion"// Replace with actual image path
    },
    {
      id: "backPack",
      title: "School Mugs",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.giftAndPromotion4,
      route:"/giftAndPromotion" // Replace with actual image path
    },
    
  ];
  export const bagsAndAccessories = [
    {
      id: "schoolUniforms",
      title: "School Merchandise",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.giftAndPromotion1,
      route:"/product/bagpack-view" // Replace with actual image path
    },
    {
      id: "idCard",
      title: "Graduation Gifts",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.giftAndPromotion2,
      route:"/product/bagpack-view" // Replace with actual image path
    },
    {
      id: "eventHosting",
      title: "Premium Diary Set",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.giftAndPromotion3,
      route:"/product/bagpack-view"// Replace with actual image path
    },
    {
      id: "backPack",
      title: "School Mugs",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.giftAndPromotion4,
      route:"/product/bagpack-view" // Replace with actual image path
    },
    
  ];
  export const eventHosting = [
    {
      id: "schoolUniforms",
      title: "School Merchandise",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.eventHostingNew1,
      route:"/product/eventhosting-view"// Replace with actual image path
    },
    {
      id: "idCard",
      title: "Graduation Gifts",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.eventHostingNew2,
      route:"/product/school-uniform" // Replace with actual image path
    },
    {
      id: "eventHosting",
      title: "Premium Diary Set",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.eventHostingNew3,
      route:"/product/school-uniform" // Replace with actual image path
    },
    {
      id: "backPack",
      title: "School Mugs",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.eventHostingNew4,
      route:"/product/school-uniform" // Replace with actual image path
    },
    
  ];
  export const productListDataWritingSection = [
    {
      id: "schoolUniforms",
      title: "School Uniforms",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.newUniformSchool,
      route:"/product/school-uniform" // Replace with actual image path
    },
    {
      id: "idCard",
      title: "ID Card",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.newUniformSchool, 
      route:"/product/school-uniform" // Replace with actual image path
    },
    {
      id: "eventHosting",
      title: "Event Hosting",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.newUniformSchool,
      route:"/product/school-uniform"  // Replace with actual image path
    },
    {
      id: "sportsUniforms",
      title: "Sports Uniforms",
      description:
      "Premium school uniforms tailored for comfort, style, and durability",
      image: Images.newUniformSchool,
      route:"/product/school-uniform"  // Replace with actual image path
    },
   
  ];
  