import React, { useState } from "react";
import Layout from "../../components/layout/Layout";
import Carousel from 'react-bootstrap/Carousel';
import { Images } from "../../constants/Images";
import "./Home.css";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import ClientsOwlCarousel from "../../components/owlCarousel/ClientsOwlCarousel";
import CountryListComp from "../../components/CommonCountryList/CountryListComp";
import ShoppingStep from "../../components/commonShoppingStep/ShoppingStep";
import { Link, useNavigate } from "react-router-dom";
import CategoriesComponent from "./CategoriesComponent";
import CommonCardBts from "../../components/commonCard/CommonCard";
import { productGiftAndPromotion, productListData, productListDataIdSection, productListDataNoteBookSection } from "../../components/dtos/PlpData";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";

const Home = () => {
    const [selectedOption, setSelectedOption] = useState("Select an option");
    const handleSelect = (eventKey, event) => {
        setSelectedOption(event.target.text);
        console.log(`Selected: ${eventKey}`);
    };
    const navigate = useNavigate();

    return (
        <Layout>
            <div className="mainContainer">
                <div className="firstSection position-relative">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 p-0">
                                <Carousel>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={Images.carousel1}
                                            alt="First slide"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={Images.carousel2}
                                            alt="Second slide"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={Images.carousel3}
                                            alt="Third slide"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={Images.carousel4}
                                            alt="Third slide"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={Images.carousel5}
                                            alt="Third slide"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={Images.carousel6}
                                            alt="Third slide"
                                        />
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                    <div className="requestCallContainer">
                        <h5>Request a call</h5>
                        <div className="inputField">
                            <input placeholder="Full Name*" />
                        </div>
                        <div className="inputField">
                            <input placeholder="School Name" />
                        </div>
                        <div className="inputField">
                            <input placeholder="10-digit Mobile Number" />
                        </div>
                        <div className="inputField">
                            <DropdownButton
                                id="dropdown-basic-button"
                                title={selectedOption}
                                onSelect={handleSelect}
                            >
                                <Dropdown.Item eventKey="1" active={selectedOption === "School Uniforms"}>School Uniforms</Dropdown.Item>
                                <Dropdown.Item eventKey="2" active={selectedOption === "ID Card"}>ID Card</Dropdown.Item>
                                <Dropdown.Item eventKey="3" active={selectedOption === "Stationary"}>Stationary</Dropdown.Item>
                                <Dropdown.Item eventKey="4" active={selectedOption === "Accessories"}>Accessories</Dropdown.Item>
                                <Dropdown.Item eventKey="5" active={selectedOption === "Writing Supplies"}>Writing Supplies</Dropdown.Item>
                            </DropdownButton>
                        </div>
                        <div className="buttonhome">
                            <button>Send</button>
                        </div>
                    </div>
                </div>
                <div className="secondSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sectionHeader">
                                    <h5 className="mb-0">Our Categories</h5>
                                </div>
                                {/* <OwlCarouselComponent /> */}
                                <CategoriesComponent />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second2ndSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <img src={Images.customisedSchoolIMG} alt="" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="thirdSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sectionHeader">
                                    <h5 className="mb-0">Why Choose Us</h5>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="whyChooseUsBox">
                                            <img src={Images.completeCustomization} className="img-fluid" alt="completeCustomization" />
                                            <h6>Complete Customization</h6>
                                            <p>Experience complete customization tailored to your school's unique identity and needs.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="whyChooseUsBox">
                                            <img src={Images.qualitiesTrust} className="img-fluid" alt="qualitiesTrust" />
                                            <h6>Quality you can trust</h6>
                                            <p>Discover the best quality school solutions, trusted by schools across India</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="whyChooseUsBox">
                                            <img src={Images.poweredTechnology} className="img-fluid" alt="poweredTechnology" />
                                            <h6>Powered by technology</h6>
                                            <p>Powered by advanced technology, delivering the best solutions to transform school management.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="whyChooseUsBox">
                                            <img src={Images.pioneered} className="img-fluid" alt="pioneered" />
                                            <h6>Pioneered by IIT Kharagpur Alumni</h6>
                                            <p>Bringing innovation and expertise to every school solution</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fourthSection">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="qualitiesAssuranceBox">
                                    <img src={Images.highQualityPersonalized} className="img-fluid w-100" alt="pioneered" />
                                    <div className="insideContent">
                                        <h5>High Quality Personalized</h5>
                                        <p>High-quality personalized solutions for your school.</p>
                                        <button onClick={()=> navigate("/product/school-uniform")}>Request For Quotation</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="qualitiesAssuranceBox">
                                    <img src={Images.customUniform} className="img-fluid w-100" alt="pioneered" />
                                    <div className="insideContent">
                                        <h5>Custom Uniform Wear</h5>
                                        <p>Showcase your brand with custom uniform wear.
                                        </p>
                                        <button onClick={()=> navigate("/product/school-uniform")}>Request For Quotation</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fourthSection">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="sectionHeader">
                                    <h5 className="mb-0">Our Products</h5>
                                </div>
                                <div className="sectionsecondHeader">
                                    <h5 className="mb-0">School Uniform</h5>
                                </div>
                                <div className="productsSection">
                                    {productListData.map((product) => (
                                        <CommonCardBts
                                            key={product.id}
                                            id={product.id}
                                            title={product.title}
                                            description={product.description}
                                            image={product.image}
                                            route={product.route}
                                        />
                                    ))}
                                </div>
                                <div className="sectionsecondHeader">
                                    <h5 className="mb-0">School ID Card</h5>
                                </div>
                                <div className="productsSection">
                                    {productListDataIdSection.map((product) => (
                                        <CommonCardBts
                                            key={product.id}
                                            id={product.id}
                                            title={product.title}
                                            description={product.description}
                                            image={product.image}
                                            route={product.route}
                                        />
                                    ))}
                                </div>
                                <div className="sectionsecondHeader">
                                    <h5 className="mb-0">Stationary</h5>
                                </div>
                                <div className="productsSection">
                                    {productListDataNoteBookSection.map((product) => (
                                        <CommonCardBts
                                            key={product.id}
                                            id={product.id}
                                            title={product.title}
                                            description={product.description}
                                            image={product.image}
                                            route={product.route}
                                        />
                                    ))}
                                </div>
                                <div className="sectionsecondHeader">
                                    <h5 className="mb-0">Gift And Promotion</h5>
                                </div>
                                <div className="productsSection">
                                    {productGiftAndPromotion.map((product) => (
                                        <CommonCardBts
                                            key={product.id}
                                            id={product.id}
                                            title={product.title}
                                            description={product.description}
                                            image={product.image}
                                            route={product.route}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fifthSection">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="seasonalWeeklysale">
                                    <div className="row h-100 align-items-center">
                                        <div className="col-sm-3 pe-sm-0">
                                            <div className="seasonalBox">
                                                <p className="number mb-0">20</p>
                                                <div className="d-flex flex-column align-items-center ms-2">
                                                    <p className="percentage mb-0">%</p>
                                                    <p className="off mb-0">OFF</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-9 ps-sm-0">
                                            <div className="seasonalRightBox">
                                                <div>
                                                    <p className="seasonal">seasonal weekly sale 2024</p>
                                                    <div className="codeBox">
                                                        <p className="useCodeText mb-0">Use code</p>
                                                        <div className="saleBox">
                                                            <p className="mb-0">Sale 2024</p>
                                                        </div>
                                                        <p className="toGetOffer mb-0">to get best offer</p>
                                                    </div>
                                                </div>
                                                <div className="seasonalRightImage">
                                                    <img alt="pioneered" src={Images.seasonalWeekly} className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sixthSection">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-6">
                                <img src={Images.hoodie} alt="" className="img-fluid w-100" />
                            </div>
                            <div className="col-6">
                                <div className="rightSixthSec">
                                    <p className="itsGood">It's good to be on the list.</p>
                                    <p className="getOff">Get 15% off your order when you sign up for our emails</p>
                                    <div className="inputField">
                                        <input placeholder="Email" className="w400" />
                                    </div>
                                    <p className="yesReceive">Yes, I'd like to receive special offer emails from VistaPrint, as well as news about products, services and my designs in progress. Read our Privacy and Cookie policy</p>
                                    <ApplicationButton btnText={"Enquiry"} className={"blackBtn40 Btnw180"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="seventhSection">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="sectionHeader">
                                    <h5 className="mb-0">Our Clients</h5>
                                </div>
                                <ClientsOwlCarousel />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ninthSection">
                    <ShoppingStep />
                </div>
            </div>
        </Layout>
    )
}

export default Home;