import React from "react";
import "./QuickView.css";
import Layout from "../../components/layout/Layout";
import { Images } from "../../constants/Images";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import OwlCarouselBp from "../../components/owlCarousel/OwlCrowselBp";
import TextInputWithLabel from "../../components/applicationInput/ApplicationInput";
import ShoppingStep from "../../components/commonShoppingStep/ShoppingStep";
import OwlCarouselEventHosting from "../../components/owlCarousel/OwlCrowselEventHosting";
import RequestQuotation from "../../components/requestQuotation/RequestQuotation";

const EventHostingView = () => {
  return (
    <Layout>
      <div className="firstSectionEventHosting">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                alt="backpack"
                src={Images.eventHosting}
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <div className="event-photography-img">
                <p className="event-photo-text">Event & Photography</p>
                <p className="bagpack-text-p-css">
                Custoking offers professional event hosting and photography services, designed to capture the essence of every school occasion.
                </p>
                <p className="bagpack-text-p-css">
                From annual days and sports meets to cultural fests and graduation ceremonies, our experienced team ensures that each moment is beautifully documented
                </p>
                <p className="bagpack-text-p-css">
                Using high-quality equipment and attention to detail, we capture vivid memories through candid and formal shots, showcasing students, staff, and school milestones for years to come.
                Seamless event hosting tailored to your school’s style, ensuring every detail is captured with professional care and quality.
                </p>
            
                <div className="eventHostingBtn">
                  <ApplicationButton
                    btnText={"Request For Quotation"}
                    type="submit"
                    className="blackheight45 Btnw300 radius30"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="secondSectionEventHosting">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="estimates-text-bp text-dark">Estimates for every events</p>
            </div>
            <div className="col-md-12">
              <OwlCarouselEventHosting />
            </div>
          </div>
        </div>
      </div>
      <div className="thirdSectionEventHosting">
      <RequestQuotation title="Get Event & Photography Quotation"/>
      </div>
      <div className="fourthSectionEventHosting">
        <div className="shopping-step-qv">
          <ShoppingStep />
        </div>
      </div>
    </Layout>
  );
};

export default EventHostingView;
