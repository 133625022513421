import React from "react";
import "./OwlCarousel.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Images } from "../../constants/Images";

const ClientsOwlCarousel = () => {
    const options = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    };
    return (
        <OwlCarousel className="owl-theme" {...options}>
            <div className="item">
                <img src={Images.newClientsImg} alt="Slide 1" />
            </div>
            <div className="item">
                <img src={Images.newClientsImg} alt="Slide 2" />
            </div>
            <div className="item">
                <img src={Images.newClientsImg} alt="Slide 3" />
            </div>
            <div className="item">
                <img src={Images.newClientsImg} alt="Slide 3" />
            </div>
        </OwlCarousel>
    )
}

export default ClientsOwlCarousel;