import React from "react";
import "./Customization.css";
const Customization = () => {
  return (
      <div className="firstSection position-relative">
        <div className="container-fluid d-flex justify-content-center">
          <div className="row">
          Customization  comming soon
          </div>
        </div>
      </div>
    
  );
};

export default Customization;
