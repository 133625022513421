/* eslint-disable react/prop-types */
import React from "react";
import './ApplicationButton.css';

// Common button
const ApplicationButton = ({
    btnText,
    className = {},
    disabled,
    isLoading,
    type,
    onClick = () => { },
    icon
}) => {
    return (
        <button onClick={onClick} className={`${className ? className : ""}`} disabled={disabled} type={type}>
            {/* {isLoading ? <ButtonLoader /> : null}  */}
            {btnText}
            {icon ? <img src={icon} className="iconsButton me-2"  alt="btn-image" /> : null}
        </button>
    );
};

export default ApplicationButton;