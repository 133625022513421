import React from "react";
import { Images } from "../../constants/Images";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import { useNavigate } from "react-router-dom";

const CategoriesComponent = () => {
    const navigate = useNavigate();
    return (
        <div className="row">
            <div className="col-4">
                <div className="scoolUniformBox">
                    <img src={Images.uniform1} alt="" className="img-fluid" />
                    <div className="uniformCateBox">
                        <h5>School Uniform</h5>
                        <ApplicationButton btnText={"Enquiry"} className={"blueButton40 Btnw220"} onClick={()=> navigate("/product/school-uniform")}/>
                    </div>
                </div>
            </div>
            <div className="col-3">
                <div className="idCardCategoriesBox">
                    <div className="idCardTopCategoriesBox">
                        <img src={Images.idCard3} alt="" className="img-fluid w-100" />
                    </div>
                    <div className="idCardBottomCategoriesBox">
                        <h5>ID Card</h5>
                        <ApplicationButton btnText={"Enquiry"} className={"blueButton40 Btnw220"} onClick={()=> navigate("/id-card")} />
                    </div>
                </div>
            </div>
            <div className="col-5">
                <div className="stationarySection">
                    <div className="row">
                        <div className="col-12">
                            <div className="stationaryBox">
                                <div className="row">
                                    <div className="col-8">
                                        <h5>Stationary</h5>
                                        <p>Get essential stationery like pens, notebooks, sticky notes, and highlighters</p>
                                        <ApplicationButton btnText={"Enquiry"} className={"blueButton40 w-100"}  onClick={()=> navigate("/stationary-view")} />
                                    </div>
                                    <div className="col-4">
                                        <div className="d-flex justify-content-end">
                                            <img src={Images.stationaryImage} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="stationaryBox mt10">
                                <div className="row">
                                    <div className="col-12">
                                        <h5>Gifts And Promotion</h5>
                                        {/* <p>Equip your space with charging stations, computer desks, tables, and more.</p> */}
                                        <ApplicationButton btnText={"Enquiry"} className={"blueButton40 w-100"}  onClick={()=> navigate("/giftAndPromotion")} />
                                    </div>
                                    <div className="col-12">
                                        <div className="giftPromotaionImage">
                                            <img src={Images.giftPromotation} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="stationaryBox mt10">
                                <div className="row">
                                    <div className="col-12">
                                        <h5>Event And Photography</h5>
                                        {/* <p>Equip your space with charging stations, computer desks, tables, and more.</p> */}
                                        <ApplicationButton btnText={"Enquiry"} className={"blueButton40 w-100"}  onClick={()=> navigate("/product/eventhosting-view")}/>
                                    </div>
                                    <div className="col-12">
                                        <div className="giftPromotaionImage eventPhotograpy">
                                            <img src={Images.photograferImg} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoriesComponent;