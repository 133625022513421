import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BagPackView, ContactUs, Customization, EnquiryPage, ErpProcess, EventHostingView,IdCard, Home, PageNotFound, Product, QuickView, StationaryView, GiftAndPromotion } from "../../pages";

const ApplicationNavigator = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/product" element={<Product />} />
                <Route path="/enquiry" element={<EnquiryPage />} />
                <Route path="/product/school-uniform" element={<QuickView />} />
                <Route path="/product/bagpack-view" element={<BagPackView />} />
                <Route path="/product/eventhosting-view" element={<EventHostingView />} />
                <Route path="/erp-process" element={<ErpProcess />} />
                <Route path="/stationary-view" element={<StationaryView />} />
                <Route path="/customization" element={<Customization />} />
                <Route path="/giftAndPromotion" element={<GiftAndPromotion />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/id-card" element={<IdCard />} />
                <Route path="/*" element={<PageNotFound />} />
            </Routes>
        </Router>   
    )
}
export default ApplicationNavigator;