import React from "react";
import Layout from "../../components/layout/Layout";
import "./PageNotFound.css";
import { Images } from "../../constants/Images";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import { Link } from "react-router-dom";
import { Icons } from "../../constants/Icons";
const PageNotFound = () => {
  return (
    <Layout>
      <div className="container  container-section">
        <div className="row container-row">
            <div className=" d-flex justify-content-center merge-text-img">
          <div className="col-md-4">
            <p className="welost-text">We lost that page...</p>
            <p className="notfoundPtagStatement">
              The page you are looking for was moved, removed or might never
              existed. Here some helpful links:
            </p>
            <div className="searchBox404">
              <input
                name="searchAnyThing"
                placeholder="What are you looking for..."
                value=""
                id=""
                type="text"
              />
              <div className="searchIcon">
                <img
                  alt="icon-search"
                  src={Icons.searchIcon}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <img
              src={Images.fourZeroImg}
              alt="fourZeroImg"
              className="fourZero4"
            />
          </div>
            </div>
        </div>
        <div className="gotoPage mb-4">
          <Link to="/">
            <ApplicationButton
              btnText={"Go to homepage"}
              type="submit"
              className="whiteBorderBtn50"
              icon={Images.rightArrow}
            />
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default PageNotFound;
